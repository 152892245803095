// WishlistPage.js
import React from 'react';
import './WishlistPage.css'; // Create this file for custom styling

const WishlistPage = () => {
  return (
    <div className="wishlist-page">
      <h1>Your Wishlist</h1>
      <p>You haven't added any items to your wishlist yet.</p>
      {/* Here you can add a list of items from the wishlist */}
    </div>
  );
};

export default WishlistPage;
