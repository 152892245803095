// RotatingBanner.js
import React, { useEffect, useState } from 'react';
import './Banner.css'; // Import the CSS file for styling

const Banner = () => {
  const [activeMessage, setActiveMessage] = useState(0);

  // Messages to display
  const messages = [
    "SUMMER SALE UP TO 50% OFF Selected Lines - FREE Insured Next Working Day Delivery",
    "Get 10% OFF your order with code SUMMER10 - (excludes discounted lines & chains)"
  ];

  // Change the message every 5 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      setActiveMessage((prev) => (prev + 1) % messages.length);
    }, 5000); // Change every 5 seconds

    return () => clearInterval(interval); // Clean up the interval on component unmount
  }, [messages.length]);

  return (
    <div className="banner">
      <p>{messages[activeMessage]}</p>
    </div>
  );
};

export default Banner;

