



import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Header.css';
import { FaHeart, FaShoppingBag, FaSearch, FaUser, FaBars } from 'react-icons/fa';

const Header = () => {
  // State to manage the mobile menu visibility
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  // Function to handle toggling the mobile menu
  const handleToggleMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <header className={`header ${isMobileMenuOpen ? 'mobile-menu-open' : ''}`}>
      <div className="header-container">
        {/* Logo Section */}
        <div className="logo-section">
          <Link to="/" className="logo">
            The Adore Jewels
          </Link>
        </div>

        {/* Search Bar */}
        <div className="search-bar">
          <input type="text" placeholder="search eg: creole earrings" />
          <button type="submit">
            <FaSearch />
          </button>
        </div>

        {/* Hamburger Menu Icon for Mobile */}
        <div className="hamburger-menu" onClick={handleToggleMenu}>
          <FaBars />
        </div>

        {/* User Action Icons */}
        <div className={`user-actions ${isMobileMenuOpen ? 'active' : ''}`}>
          <Link to="/login" className="user-action">
            <FaUser /> <span>Log in</span>
          </Link>
          <Link to="/wishlist" className="user-action">
            <FaHeart /> <span>Wishlist</span>
          </Link>
          <Link to="/shopping-bag" className="user-action">
            <FaShoppingBag /> <span>Shopping Bag</span>
          </Link>
        </div>
      </div>

      {/* Mobile Menu */}
      {isMobileMenuOpen && (
        <div className="mobile-menu">
          <button className="close-menu" onClick={handleToggleMenu}>Close</button>
          <Link to="/" className="mobile-menu-item">Home</Link>
          <Link to="/login" className="mobile-menu-item">Log In</Link>
          <Link to="/contact-us" className="mobile-menu-item">Contact Us</Link>
          <Link to="/gift-items" className="mobile-menu-item">Gift Items</Link>
          <Link to="/earrings" className="mobile-menu-item">Earrings</Link>
          <Link to="/necklaces" className="mobile-menu-item">Necklaces & Pendants</Link>
          <Link to="/gold-chain" className="mobile-menu-item">Gold Chain</Link>
          <Link to="/bracelets" className="mobile-menu-item">Bracelets</Link>
          <Link to="/anklets" className="mobile-menu-item">Anklets</Link>
          <Link to="/rings" className="mobile-menu-item">Rings</Link>
          <Link to="/lockets" className="mobile-menu-item">Lockets</Link>
          <Link to="/jewellery-sets" className="mobile-menu-item">Jewellery Sets</Link>
          <Link to="/sale" className="mobile-menu-item">Sale up to 50% OFF</Link>
        </div>
      )}
    </header>
  );
};

export default Header;
