// LoginPage.js
import React from 'react';
import './LoginPage.css';

const LoginPage = () => {
  return (
    <div className="login-signup-container">
      {/* Login Section */}
      <div className="login-section">
        <h2>Account Login</h2>
        <form>
          <div className="input-group">
            <label htmlFor="email">*Email Address</label>
            <input type="email" id="email" placeholder="Enter your email" required />
          </div>
          <div className="input-group">
            <label htmlFor="password">*Password</label>
            <input type="password" id="password" placeholder="Enter your password" required />
          </div>
          <div className="forgot-password">
          <button onClick={() => alert('Password reset link not implemented yet')} style={{ background: 'none', border: 'none', color: '#2a8fba', cursor: 'pointer', textDecoration: 'underline', padding: '0' }}>
            Forgotten your password? Click here
            </button>
          </div>
          <button type="submit" className="login-button">
            LOGIN
          </button>
        </form>
      </div>

      {/* Sign-Up Section */}
      <div className="signup-section">
        <h2>New Here?</h2>
        <ul>
          <li>🚀 Order even faster in future</li>
          <li>📦 Store addresses and cards</li>
          <li>👁️ View and track purchases</li>
        </ul>
        <button className="signup-button">CREATE ACCOUNT</button>
      </div>
    </div>
  );
};

export default LoginPage;
