// ShoppingBagPage.js
import React from 'react';
import './ShoppingBagPage.css'; // Create this file for custom styling

const ShoppingBagPage = () => {
  return (
    <div className="shopping-bag-page">
      <h1>Your Shopping Bag</h1>
      <p>Your shopping bag is currently empty.</p>
      {/* Here you can add a list of items from the shopping bag */}
    </div>
  );
};

export default ShoppingBagPage;
