import React from 'react';
import './HomePage.css';
import CategoryList from '../components/CategoryList'; // Adjust the path if necessary
import Banner from '../components/Banner'; // Adjust the path if necessary

const HomePage = () => {
  return (
    <div className="home">
      <Banner /> {/* Banner component */}
      <div className="homepage-content"> {/* Separate div for the content */}
        <h1>Welcome to The Fine Jewellery Company</h1>
        <p>Browse our wide selection of earrings, necklaces, bracelets, and more!</p>
        <CategoryList /> {/* CategoryList if required */}
      </div>
    </div>
  );
};

export default HomePage;
