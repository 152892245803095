// CategoryItem.js
import React from 'react';
import { motion } from 'framer-motion';
import './CategoryItem.css'; // Make sure you import the CSS file

const CategoryItem = ({ name, imageUrl }) => {
  return (
    <motion.div
      className="category-item"
      whileHover={{
        scale: 1.05,
        boxShadow: '0px 4px 10px rgba(0,0,0,0.2)',
      }}
      whileTap={{ scale: 0.95 }}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.3 }}
    >
      <div className="image-wrapper">
        <img src={imageUrl} alt={name} className="category-image" />
      </div>
      <p className="category-name">Shop {name}</p>
    </motion.div>
  );
};

export default CategoryItem;

