import React from 'react';
import { useState } from 'react';

function Sidebar() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={`sidebar ${isOpen ? 'open' : ''}`}>
      <button className="btn btn-primary" onClick={toggleSidebar}>
        {isOpen ? 'Close' : 'Menu'}
      </button>
      <nav className={`nav ${isOpen ? 'open' : ''}`}>
        <ul>
          <li>Earrings</li>
          <li>Necklaces & Pendants</li>
          <li>Gold Chain</li>
          <li>Bracelets</li>
          <li>Anklets</li>
          <li>Lockets</li>
          <li>Rings</li>
          <li style={{ color: 'red' }}>SALE UP TO 50% OFF</li>
        </ul>
      </nav>
    </div>
  );
}

export default Sidebar;
