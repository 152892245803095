// CategoryList.js
import React from 'react';
import CategoryItem from './CategoryItem';
import earrings from '../assets/earrings.jpg';
import necklaces from '../assets/necklaces.jpg';
import goldChain from '../assets/gold-chain.jpg';
import bracelets from '../assets/bracelets.jpg';

const categories = [
  { name: 'Earrings', imageUrl: earrings },
  { name: 'Earrings1', imageUrl: earrings },
  { name: 'Necklaces & Pendants', imageUrl: necklaces  },
  { name: 'Necklaces & Pendants1', imageUrl: necklaces  },
  { name: 'Gold Chain', imageUrl: goldChain },
  { name: 'Gold Chain1', imageUrl: goldChain },
  { name: 'Bracelets', imageUrl: bracelets },
  { name: 'Bracelets1', imageUrl: bracelets },
];

const CategoryList = () => {
    return (
      <div className="category-list">
        {categories.map((category) => (
          <CategoryItem key={category.name} name={category.name} imageUrl={category.imageUrl} />
        ))}
      </div>
    );
  };

export default CategoryList;
