// Footer.js
import React from 'react';
import { FaTwitter, FaInstagram, FaFacebook, FaPinterest } from 'react-icons/fa'; // Importing icons
import './Footer.css'; // Import the CSS file

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        {/* Our Company Section */}
        <div className="footer-section">
          <h4>Our Company</h4>
          <ul>
            <li><a href="/about">About Us</a></li>
            <li><a href="/contact">Contact Us</a></li>
          </ul>
        </div>

        {/* Your Security Section */}
        <div className="footer-section">
          <h4>Your Security</h4>
          <ul>
            <li><a href="/terms">T&C's</a></li>
            <li><a href="/privacy-policy">Privacy Policy</a></li>
            <li><a href="/delivery">Delivery</a></li>
            <li><a href="/returns">Returns - 100-day Satisfaction Guarantee</a></li>
          </ul>
        </div>

        {/* Customer Service Section */}
        <div className="footer-section">
          <h4>Customer Service</h4>
          <ul>
            <li><a href="/account">Your Account</a></li>
            <li><a href="/faq">FAQ's</a></li>
            <li><a href="/complaints-policy">Complaints Policy</a></li>
          </ul>
        </div>

        {/* More Info Section */}
        <div className="footer-section">
          <h4>More Info</h4>
          <ul>
            <li><a href="/precious-metal-guide">Precious Metal Guide</a></li>
            <li><a href="/hallmark-guide">Hallmark Guide</a></li>
            <li><a href="/gemstone-guide">Gemstone Guide</a></li>
            <li><a href="/diamond-guide">Diamond Guide</a></li>
            <li><a href="/jewellery-care-guide">Jewellery Care Guide</a></li>
            <li><a href="/buying-guide">Buying Guide</a></li>
          </ul>
        </div>

        {/* Contact and Social Media Section */}
        <div className="footer-section contact-section">
          <h4>Contact Us</h4>
          <p>Call us on 0121 634 8070</p>
          <p>Mon - Fri 9:00am to 5:00pm</p>

          <div className="social-icons">
            <a href="https://twitter.com" target="_blank" rel="noopener noreferrer"><FaTwitter /></a>
            <a href="https://instagram.com" target="_blank" rel="noopener noreferrer"><FaInstagram /></a>
            <a href="https://facebook.com" target="_blank" rel="noopener noreferrer"><FaFacebook /></a>
            <a href="https://pinterest.com" target="_blank" rel="noopener noreferrer"><FaPinterest /></a>
          </div>
        </div>
      </div>

      {/* Footer Bottom Section */}
      <div className="footer-bottom">
        <p>Jewellery Quarter Bullion Limited trading as The Fine Jewellery Company is not a lender.</p>
        <p>&copy; 2024 The Fine Jewellery Company. All Rights Reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;

