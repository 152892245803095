// App.js
import React, { useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import HomePage from './pages/HomePage'; // Your existing home page
import Header from './components/Header';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
// import SideMenu from './components/SideMenu';
import LoginPage from './pages/LoginPage';
import WishlistPage from './pages/WishlistPage'; // Import WishlistPage
import ShoppingBagPage from './pages/ShoppingBagPage'; // Import ShoppingBagPage
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import Sidebar from './components/Sidebar';

// import CategoryList from './components/CategoryList'; // Ensure this path is correct
import './App.css';

function App() {
  // Manage modal state here
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Get the current location
  const location = useLocation();

  // Determine if we are on the home page
  const isHomePage = location.pathname === "/";

  return (
    <div className="App">
      <Header isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
      <Navbar />
      <div className="d-flex">
        <Sidebar />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/login" element={<LoginPage />} /> {/* Route for Login Page */}
          <Route path="/WishlistPage" element={<WishlistPage />} /> {/* Route for Login Page */}
          <Route path="/shoppingbag" element={<ShoppingBagPage />} />
          {/* Add more routes here as needed */}
        </Routes>
      </div>

      <Footer />
    </div>
  );
}

export default App;
