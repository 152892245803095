import React from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css'; // Import the CSS file

const Navbar = ({ isMobileMenuOpen }) => {
  return (
    <nav className={`navbar ${isMobileMenuOpen ? 'active' : ''}`}>
      <ul>
        <li><Link to="/female">Female</Link></li>
        <li><Link to="/children">Children</Link></li>
        <li><Link to="/gift-items">Gift Items</Link></li>
        <li><Link to="/bracelets">Bracelets</Link></li>
        {/* Additional links can be uncommented and added here */}
      </ul>
    </nav>
  );
};

export default Navbar;
